<template>
  <v-container fluid>
    <v-row justify="space-around">
      <v-col cols="12" sm="10" xl="8">
        <v-card>
          <v-card-title>
            Mot de passe oublié
          </v-card-title>
          <v-card-text>
            Veuillez entrer votre adresse pour réinitialiser votre mot de passe.
            <v-form ref="forgotPassForm">
              <v-text-field type="email" label="Email" v-model="email"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row justify="end">
              <v-col cols="2">
                <v-btn color="primary"  @click="sendResetEmail">Envoyer</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
name: "PasswordForgotten",
  data: function () {
    return {
      email: null,
    }
  },
  methods: {
    async sendResetEmail() {
      if(this.$refs.forgotPassForm.validate()) {
        let result = await this.$store.dispatch("passwordreset/sendResetEmail", this.email);
        if(result === "success") {
          await this.$store.dispatch("messages/announceSuccess", "Email envoyé, veuillez consulter votre messagerie.");
        } else {
          await this.$store.dispatch("messages/announceError", "Aucun compte n'a été trouvé à cette adresse email.")
        }
      }
     }
  }
}
</script>

<style scoped>

</style>